import { MatxLoading } from 'app/components/miscellaneous';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (userEmail, password) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
      email: userEmail,
      password
    });

    const { _id, name, email, token } = response.data;

    localStorage.setItem('accessToken', token);
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('userId', _id);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: { _id, name, email }
      }
    });
  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', {
      email,
      username,
      password
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          //   const response = await axios.get('/');
          //   const { user } = response.data;

          const secretKey = 'aicomscreenifyjstaccessdeniedforanyreason'; // Replace with your actual secret key
          const decodedToken = jwtDecode(accessToken);

          if (decodedToken.exp > Date.now() / 1000) {
            setSession(accessToken);
            dispatch({
              type: 'INIT',
              payload: {
                isAuthenticated: true,
                user: {
                  name: localStorage.getItem('name'),
                  email: localStorage.getItem('email')
                }
              }
            });
            return; // Exit the function if token is valid
          }

          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user: {
                name: localStorage.getItem('name'),
                email: localStorage.getItem('email')
              }
            }
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

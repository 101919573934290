import { lazy } from 'react';
import Loadable from 'app/components/miscellaneous/Loadable';
const JobTests = Loadable(lazy(() => import('./jobtests/JobTests')));
const AssessmentForm = Loadable(lazy(() => import('./assessment/assessmentForm')));
const ResumeMatch = Loadable(lazy(() => import('./resumematch/ResumeMatch')));

const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const materialRoutes = [
  {
    path: '/assessment/form',
    element: <AssessmentForm />
  },

  {
    path: '/assessment/jobtests',
    element: <JobTests />
  },

  {
    path: '/resume/match',
    element: <ResumeMatch />
  },

  {
    path: '/material/icons',
    element: <AppIcon />
  }
];

export default materialRoutes;
